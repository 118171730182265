import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import { Container, Graphics, InteractionEvent, Sprite, Texture, settings } from 'pixi.js';

import { ResourceTypes } from '../../../resources';
import { dropShadowFilter } from '../../../utils';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TextField } from '../../components/TextField';

import { Dot } from './Dot';
import {
  CAROUSEL_ANIMATION_DURATION,
  CAROUSEL_ARROWS_SIZE,
  CAROUSEL_CONTROLS_COLOR,
  CAROUSEL_DOTS_GAP,
  CAROUSEL_DOTS_SIZE,
  CAROUSEL_LETTER_SIZE,
  CAROUSEL_TEXT_DIVIDE_COLOR,
  CAROUSEL_TEXT_DIVIDE_SECOND_COLOR,
  CAROUSEL_TEXT_DIVIDE_THIRD_COLOR,
  CAROUSEL_TEXT_MOBILE_LETTER,
  CAROUSEL_TEXT_MOBILE_SIZE,
  CAROUSEL_TEXT_SIZE,
  CAROUSEL_TEXT_WORD_WRAP_WIDTH,
  DEFAULT_CAROUSEL_TEXT,
} from './config';

interface ISlide {
  img: ResourceTypes;
  txt: string;
}

class Carousel extends Container {
  private slides: ISlide[];

  private slidesContainer: Container;

  private controlsContainer: Container;

  private dots: Container;

  private prevArrow: Graphics;

  private nextArrow: Graphics;

  private isDragging: boolean;

  private zeroPosition: number;

  private screenWidth: number;

  private slidesCount: number;

  private slideIndex: number;

  public slideWidth: number;

  public slideHeight: number;

  public imageWidth: number;

  public imageHeight: number;

  constructor(height: number, slides: ISlide[]) {
    super();
    this.isDragging = false;
    this.zeroPosition = 0;
    this.screenWidth = 0;
    this.slidesCount = 0;
    this.slideIndex = 0;
    this.slideWidth = 0;
    this.slideHeight = 0;
    this.imageWidth = 0;
    this.imageHeight = 0;
    this.controlsContainer = new Container();
    this.slides = slides;
    this.slidesContainer = this.initSlides(height);
    this.prevArrow = this.initPrevArrow();
    this.dots = this.initDots();
    this.nextArrow = this.initNextArrow();
    this.controlsContainer.addChild(this.prevArrow);
    this.controlsContainer.addChild(this.dots);
    this.controlsContainer.addChild(this.nextArrow);
    this.controlsContainer.x = this.controlsContainer.width / 2;
    this.controlsContainer.renderable = this.slides.length > 1;
    this.addChild(this.slidesContainer);
    this.addChild(this.controlsContainer);
  }

  private initLine = (): Graphics => {
    const line = new Graphics();
    const dropShadow = dropShadowFilter({
      color: 0xfffffd,
      distance: 0,
    });
    line.moveTo(0, 0);
    line.lineStyle(3, 0xfffffd);
    line.moveTo(0, 0);
    line.lineTo(500, 0);
    line.y = 0;
    line.filters = [dropShadow];
    return line;
  };

  private initSlides = (height: number): Container => {
    const slides = new Container();
    const widthArr = [];
    const heightArr = [];
    for (let i = 0; i < this.slides.length; i++) {
      const container = new Container();
      const texture = Texture.from(this.slides[i].img);
      const image = new Sprite(texture);
      image.anchor.set(0.5, 0);
      image.x = 0;
      image.name = `image_${i}`;
      image.width = (image.width * height) / image.height - 10;
      image.height = (image.height * height) / image.height;
      const text = new TextField(i18n.t(this.slides[i].txt), image.width / 2, 280, undefined, true, {
        default: {
          ...DEFAULT_CAROUSEL_TEXT,
          fontSize: isMobile ? CAROUSEL_TEXT_MOBILE_SIZE : CAROUSEL_TEXT_SIZE,
          wordWrapWidth: CAROUSEL_TEXT_WORD_WRAP_WIDTH || image.width,
        },
        span: {
          fill: CAROUSEL_TEXT_DIVIDE_COLOR,
        },
        p: {
          fill: CAROUSEL_TEXT_DIVIDE_SECOND_COLOR,
        },
        h: {
          fill: CAROUSEL_TEXT_DIVIDE_THIRD_COLOR,
        },
        scaleLetter: {
          fontSize: isMobile ? CAROUSEL_TEXT_MOBILE_LETTER : CAROUSEL_LETTER_SIZE,
        },
      });
      text.text.anchor.set(0.5, 1);
      text.text.y = i === 2 ? 300 : image.height - 70;
      text.text.x = i === 2 ? -160 : 190;
      container.addChild(image);
      container.addChild(text.getText());
      widthArr.push(container.width);
      heightArr.push(container.height);
      slides.addChild(container);
    }
    this.slideWidth = Math.max(...widthArr);
    this.slideHeight = Math.max(...heightArr);
    slides.interactive = true;
    slides.buttonMode = true;
    slides.on('pointerdown', this.handleStartDragging);
    slides.on('pointerup', this.handleStopDragging);
    slides.on('pointermove', this.handleDragging);
    slides.on('pointerout', this.handleStopDragging);

    return slides;
  };

  private initDots = (): Container => {
    const dotsContainer = new Container();
    for (let i = 0; i < this.slides.length; i++) {
      const dot = new Dot(`${i + 1}`, i === 0);
      dot.interactive = true;
      dot.buttonMode = true;
      dot.name = `${i}`;
      dot.x = i * (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_GAP);
      dot.on('pointerdown', () => {
        this.handleControls(+dot.name, i);
      });
      dotsContainer.addChild(dot);
    }
    dotsContainer.x = this.prevArrow.width * 2;
    return dotsContainer;
  };

  private createTriangle = (size: number, color: number, direction: 'left' | 'right'): Graphics => {
    const triangle = new Graphics();
    const dropShadow = dropShadowFilter({
      color: 0x000000,
      resolution: 2 * settings.FILTER_RESOLUTION,
    });
    const triangleWidth = size;
    const triangleHeight = triangleWidth;
    const triangleHalfway = triangleWidth / 2;
    if (direction === 'left') {
      triangle.beginFill(color, 1);
      triangle.lineStyle(0, color, 1);
      triangle.moveTo(triangleWidth, 0);
      triangle.lineTo(triangleHeight, triangleHeight);
      triangle.lineTo(0, triangleHalfway);
      triangle.endFill();
    }
    if (direction === 'right') {
      triangle.beginFill(color, 1);
      triangle.lineStyle(0, color, 1);
      triangle.moveTo(0, 0);
      triangle.lineTo(triangleHeight, triangleHalfway);
      triangle.lineTo(0, triangleHeight);
      triangle.endFill();
    }
    triangle.filters = [dropShadow];

    return triangle;
  };

  private initPrevArrow = (): Graphics => {
    const arrow = this.createTriangle(CAROUSEL_ARROWS_SIZE, CAROUSEL_CONTROLS_COLOR, 'left');
    arrow.interactive = true;
    arrow.buttonMode = true;
    arrow.on('pointerdown', () => {
      const slidesCount = this.slidesCount - 1;
      const slideIndex = this.slideIndex - 1;
      this.handleControls(slidesCount, slideIndex);
    });
    return arrow;
  };

  private initNextArrow = (): Graphics => {
    const arrow = this.createTriangle(CAROUSEL_ARROWS_SIZE, CAROUSEL_CONTROLS_COLOR, 'right');
    arrow.interactive = true;
    arrow.buttonMode = true;
    arrow.x = this.dots.width - 10 + arrow.width * 3;
    arrow.on('pointerdown', () => {
      const slidesCount = this.slidesCount + 1;
      const slideIndex = this.slideIndex + 1;
      this.handleControls(slidesCount, slideIndex);
    });
    return arrow;
  };

  private handleStartDragging = (eventData: InteractionEvent): void => {
    const positions = eventData.data.global;
    this.isDragging = true;
    this.zeroPosition = positions.x;
  };

  private handleStopDragging = (): void => {
    this.isDragging = false;
  };

  private handleDragging = (eventData: InteractionEvent): void => {
    if (this.isDragging) {
      const positions = eventData.data.global;
      if (this.zeroPosition > positions.x) {
        const value = this.zeroPosition - positions.x;
        if (value > 20) {
          this.isDragging = false;
          const slidesCount = this.slidesCount + 1;
          const slideIndex = this.slideIndex + 1;
          this.handleControls(slidesCount, slideIndex);
        }
      }
      if (this.zeroPosition < positions.x) {
        const value = positions.x - this.zeroPosition;
        if (value > 20) {
          this.isDragging = false;
          const slidesCount = this.slidesCount - 1;
          const slideIndex = this.slideIndex - 1;
          this.handleControls(slidesCount, slideIndex);
        }
      }
    }
  };

  private handleControls = (slidesCount: number, slideIndex: number): void => {
    if (slideIndex < 0) {
      this.slideIndex = this.slides.length - 1;
    } else if (slideIndex > this.slides.length - 1) {
      this.slideIndex = 0;
    } else {
      this.slideIndex = slideIndex;
    }
    this.dots.children[this.slideIndex].name = `${slidesCount}`;

    if (this.slidesCount < slidesCount) {
      if (this.slideIndex === 0) {
        for (let i = 0; i < this.dots.children.length; i++) {
          this.dots.children[i].name = `${slidesCount + i}`;
        }
      }
    } else if (this.slideIndex === this.dots.children.length - 1) {
      for (let i = 0; i < this.dots.children.length; i++) {
        this.dots.children[this.slideIndex - i].name = `${slidesCount - i}`;
      }
    }

    this.slidesCount = slidesCount;

    this.dots.children[this.slideIndex].name = `${this.slidesCount}`;
    this.slidesContainer.children[this.slideIndex].x = this.slidesCount * this.screenWidth + this.screenWidth / 2;

    for (let i = 0; i < this.dots.children.length; i++) {
      (this.dots.children[i] as Dot).setActive(i === this.slideIndex);
    }

    const target = this.slidesCount * this.screenWidth;
    const propertyBeginValue = this.slidesContainer.pivot.x;
    this.slidesContainer.pivot.x = target;
    const moveXAnimation = new Tween({
      object: this.slidesContainer.pivot,
      property: TweenProperties.X,
      propertyBeginValue,
      target,
      duration: CAROUSEL_ANIMATION_DURATION,
    });
    moveXAnimation.start();
  };

  public setSize = (width: number, height: number, bottomGap: number): void => {
    this.screenWidth = width;

    for (let i = 0; i < this.slides.length; i++) {
      if (width < this.slideWidth) {
        const scaleX = width / this.slideWidth;
        const scaleY = (width * (this.slideHeight / this.slideWidth)) / this.slideHeight;

        this.slidesContainer.children[i].scale.set(scaleX, scaleY);
      }

      const heightScale = (height - bottomGap - this.controlsContainer.height) / this.slideHeight;
      if (width > height && heightScale <= 1) {
        this.slidesContainer.children[i].scale.set(heightScale);
      }

      this.slidesContainer.children[i].transform.position.set(i * width + width / 2, 0);
      this.slidesContainer.children[this.slideIndex].x = this.slidesCount * this.screenWidth + this.screenWidth / 2;
    }
    const imageBounds = this.slidesContainer.getChildByName(`image_0`, true)!.getBounds();
    this.imageWidth = imageBounds.width / 4;
    this.imageHeight = imageBounds.height / 4;
    this.controlsContainer.y = this.slidesContainer.height + 5;
    this.controlsContainer.x = width / 2 - this.controlsContainer.width / 2;

    this.slidesContainer.pivot.set(this.slidesCount * width, 0);
  };
}
export default Carousel;
